import {newaxios} from '../lib/http'

export const GetRxchangeRate = (data)=>{
  return newaxios.requset({
    url:`https://api.frankfurter.app/latest?base=${data.base}`,
    type:'get',
  })

}

export const GetCurrencies = ()=>{
  return newaxios.requset({
    url:`https://api.frankfurter.app/currencies`,
    type:'get',
  })
}