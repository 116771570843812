import Vue from 'vue'
import App from './App.vue'
import router from './router' // 引入路由配置
import { Button, Input, Select,Option,Row,Col,Form,FormItem,InputNumber,Message,Skeleton ,SkeletonItem,Alert} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(Input)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(InputNumber)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Alert)
Vue.prototype.$message = Message
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
