import axios from "axios"
class  HttpRequest {
  constructor(){

  }
  interceptors(instance){
    instance.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    })
    instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
  }
  requset(options){
    const instance = axios.create()
    this.interceptors(instance)
    return instance(options)
  }
}

let newaxios  = new HttpRequest()

export {newaxios}