// 引入 Vue 和 Vue Router
import Vue from 'vue'
import Router from 'vue-router'

// 引入页面组件
import Home from '@/App.vue'

// 告诉 Vue 使用 Vue Router
Vue.use(Router)

// 创建并导出路由实例
export default new Router({
  mode: 'history', // 使用 history 模式去掉 URL 中的 # 号
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
  ]
})
